import { MdEmail, MdPhone, MdBusiness, MdNumbers } from 'react-icons/md';

const Impressum = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="max-w-3xl mx-auto px-6">
        <div className="bg-white rounded-lg shadow-md p-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-8 border-b pb-4">Impressum</h1>
          
          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Anschrift</h2>
              <div className="flex items-start space-x-3 text-gray-600">
                <MdBusiness className="text-xl mt-1 text-blue-600" />
                <div>
                  <p className="font-medium">Zondiac UG (haftungsbeschränkt)</p>
                  <p>Hauptstr. 120</p>
                  <p>75056 Sulzfeld</p>
                  <p>Germany</p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Kontakt</h2>
              <div className="space-y-3">
                <div className="flex items-center space-x-3 text-gray-600">
                  <MdEmail className="text-xl text-blue-600" />
                  <a href="mailto:help@zondiac.com" className="hover:text-blue-600 transition-colors">
                    help@zondiac.com
                  </a>
                </div>
                <div className="flex items-center space-x-3 text-gray-600">
                  <MdPhone className="text-xl text-blue-600" />
                  <a href="tel:+72699606318" className="hover:text-blue-600 transition-colors">
                    +7269 9606318
                  </a>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Registrierung</h2>
              <div className="flex items-start space-x-3 text-gray-600">
                <MdNumbers className="text-xl mt-1 text-blue-600" />
                <div>
                  <p>Commercial Register: Amtsgericht Mannheim</p>
                  <p>Registration Number: HRB 736813</p>
                  <p>VAT ID: DE2441255232</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
