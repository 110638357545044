import { Link } from '@tanstack/react-router';

export default function Footer() {
  return (
    <footer className="bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 py-12 md:py-16">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-12">
          <div className="col-span-2 md:col-span-1">
            <h3 className="text-2xl font-bold bg-gradient-to-r from-red-400 to-red-600 bg-clip-text text-transparent mb-4">
              Zondiac
            </h3>
            <p className="text-gray-400">
              Wir transformieren Unternehmen durch innovative digitale Lösungen.
            </p>
          </div>
          
          <div className="w-full">
            <h4 className="text-white font-semibold mb-4">Leistungen</h4>
            <ul className="space-y-2">
              {['Webentwicklung', 'Cloud Services', 'Beratung'].map(service => (
                <li key={service}>
                  <Link to="/services" className="text-gray-400 hover:text-red-400 transition-colors">
                    {service}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          
          <div className="w-full">
            <h4 className="text-white font-semibold mb-4">Rechtliches</h4>
            <ul className="space-y-2">
              <li>
                <Link to="/impressum" className="text-gray-400 hover:text-red-400 transition-colors">
                  Impressum
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-400 hover:text-red-400 transition-colors">
                  Datenschutz
                </Link>
              </li>
            </ul>
          </div>
          
          <div className="col-span-2 md:col-span-1">
            <h4 className="text-white font-semibold mb-4">Kontakt</h4>
            <ul className="space-y-2 text-gray-400">
              <li>help@zondiac.com</li>
              <li>+49 7269 9606318</li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-gray-800 mt-8 md:mt-12 pt-8 flex flex-col md:flex-row justify-between items-center gap-4">
          <p className="text-gray-400 text-sm text-center md:text-left">© 2024 Zondiac. All rights reserved.</p>
          <div className="flex space-x-6">
            {/* Add social media icons here */}
          </div>
        </div>
      </div>
    </footer>
  );
}
