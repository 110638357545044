import { Link } from '@tanstack/react-router';
import { motion } from 'framer-motion';

const Home = () => {
  return (
    <>
      {/* Hero Section */}
      <div className="relative min-h-[calc(100vh-4rem)] md:min-h-screen flex items-center">
        <div className="absolute inset-0 bg-gradient-to-br from-red-50 to-orange-50" />
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10" />
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="relative max-w-7xl mx-auto px-4 py-20 md:py-32"
        >
          <div className="text-center">
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h1 className="text-4xl sm:text-6xl md:text-8xl font-bold mb-6 md:mb-8 bg-gradient-to-r from-red-600 to-red-800 bg-clip-text text-transparent leading-tight px-4">
                Gestalten Sie Ihre digitale Zukunft
              </h1>
            </motion.div>
            <p className="text-lg sm:text-xl md:text-2xl text-gray-600 mb-8 md:mb-12 max-w-3xl mx-auto leading-relaxed px-4">
              Wir entwickeln innovative digitale Lösungen, die Unternehmen in die nächste Generation führen.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 justify-center px-4">
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Link
                  to="/contact"
                  className="bg-gradient-to-r from-red-600 to-red-800 text-white px-10 py-5 rounded-full text-lg font-medium hover:shadow-2xl hover:shadow-red-500/20 transition-all"
                >
                  Jetzt starten
                </Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Link
                  to="/services"
                  className="bg-white text-gray-800 px-10 py-5 rounded-full text-lg font-medium hover:shadow-2xl transition-all border hover:border-red-500"
                >
                  Mehr erfahren
                </Link>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Features Section */}
      <div className="py-20 md:py-32 bg-white relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-red-50/50 to-transparent" />
        <div className="max-w-7xl mx-auto px-4 relative">
          <motion.h2 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-5xl font-bold text-center mb-20 bg-gradient-to-r from-red-600 to-red-800 bg-clip-text text-transparent"
          >
            Unsere Leistungen
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-12 px-4">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="group relative bg-white p-8 rounded-3xl hover:shadow-2xl transition-all duration-500 border hover:border-red-500"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-red-50 to-orange-50 rounded-3xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                <div className="relative">
                  <h3 className="text-2xl font-bold mb-4 text-gray-900">{feature.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{feature.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      <div className="relative py-32 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-red-800" />
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-20" />
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="relative max-w-7xl mx-auto px-4 text-center"
        >
          <h2 className="text-5xl font-bold mb-8 text-white">
            Bereit für den nächsten Schritt?
          </h2>
          <p className="text-2xl mb-12 text-white/90 max-w-3xl mx-auto">
            Lassen Sie uns gemeinsam Ihre digitale Transformation gestalten.
          </p>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link
              to="/contact"
              className="bg-white text-gray-900 px-12 py-6 rounded-full text-xl font-medium hover:shadow-2xl transition-all inline-block"
            >
              Kontakt aufnehmen
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
};

const features = [
  {
    title: "IT-Beratung",
    description: "Strategische Beratung für eine zukunftssichere IT-Infrastruktur."
  },
  {
    title: "Cloud Services",
    description: "Sichere und skalierbare Cloud-Lösungen für Ihr Unternehmen."
  },
  {
    title: "Cybersecurity",
    description: "Umfassender Schutz Ihrer sensiblen Unternehmensdaten."
  }
];

export default Home;
