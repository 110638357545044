import { useState } from 'react'
import { Link } from '@tanstack/react-router'

type ValidRoute = '/' | '/services' | '/contact' | '/impressum' | '/privacy';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white/80 backdrop-blur-md fixed w-full z-50 shadow-sm">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16 md:h-20">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/" className="text-xl md:text-2xl font-bold bg-gradient-to-r from-red-600 to-red-800 bg-clip-text text-transparent">
              Zondiac
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            {[
              ['Startseite', ''],
              ['Leistungen', 'services'],
              ['Kontakt', 'contact']
            ].map(([label, path]) => (
              <Link
                key={label}
                to={`/${path}` as ValidRoute}
                className="relative text-gray-800 hover:text-red-600 transition-colors group py-2"
              >
                {label}
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-600 transform scale-x-0 transition-transform group-hover:scale-x-100" />
              </Link>
            ))}
            <Link
              to="/contact"
              className="bg-gradient-to-r from-red-600 to-red-800 text-white px-6 py-2 rounded-full hover:shadow-lg transition-all"
            >
              Kontakt aufnehmen
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden flex items-center p-2 rounded-lg hover:bg-gray-100 transition-colors"
            aria-label="Toggle menu"
          >
            <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        <div className={`md:hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-64 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
          <div className="py-2 space-y-1">
            {[
              ['Startseite', ''],
              ['Leistungen', 'services'],
              ['Kontakt', 'contact']
            ].map(([label, path]) => (
              <Link
                key={label}
                to={`/${path}` as ValidRoute}
                className="block px-4 py-3 text-gray-800 hover:bg-red-50 hover:text-red-600 rounded-lg transition-colors"
                onClick={() => setIsOpen(false)}
              >
                {label}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}
