import { motion } from 'framer-motion';
import { FiMonitor, FiSmartphone, FiSettings } from 'react-icons/fi';

const Services = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-red-50 to-orange-50 pt-16 md:pt-20">
      <div className="max-w-7xl mx-auto px-4 py-12 md:py-32">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12 md:mb-20"
        >
          <h1 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-red-600 to-red-800 bg-clip-text text-transparent mb-4 md:mb-6 px-4">
            Unsere Dienstleistungen
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Maßgeschneiderte Lösungen für Ihren digitalen Erfolg
          </p>
        </motion.div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
          <ServiceCard 
            title="Webentwicklung" 
            description="Moderne und responsive Webanwendungen mit neuester Technologie." 
            icon={<FiMonitor />}
            delay={0}
          />
          <ServiceCard 
            title="Mobile Entwicklung" 
            description="Native und plattformübergreifende mobile Apps für iOS und Android." 
            icon={<FiSmartphone />}
            delay={0.2}
          />
          <ServiceCard 
            title="Beratung" 
            description="Strategische technische Beratung und zukunftssichere Architektur." 
            icon={<FiSettings />}
            delay={0.4}
          />
        </div>
      </div>
    </div>
  );
};

const ServiceCard = ({ title, description, icon, delay }: { 
  title: string; 
  description: string; 
  icon: React.ReactNode;
  delay: number;
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay, duration: 0.8 }}
    whileHover={{ y: -10 }}
    className="group relative bg-white p-6 md:p-8 rounded-3xl shadow-xl hover:shadow-2xl transition-all duration-500"
  >
    <div className="absolute inset-0 bg-gradient-to-br from-red-500 to-red-800 opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-3xl -z-10" />
    <div className="relative z-10 flex flex-col items-start">
      <div className="text-3xl text-red-600 mb-6 group-hover:text-white transition-colors">
        {icon}
      </div>
      <h3 className="text-2xl font-bold mb-4 text-gray-900 group-hover:text-white transition-colors">
        {title}
      </h3>
      <p className="text-gray-600 group-hover:text-white/90 transition-colors">
        {description}
      </p>
    </div>
  </motion.div>
);

export default Services;
